@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

.hero {
  max-height: 1080px;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heroMenu {
  width: 100%;
  height: 75px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  z-index: 2;
}

.heroMenuItem {
  transition: 150ms all ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: rgba(0, 0, 0, 1);

  svg {
    position: absolute;
    transition: 300ms ease all;
  }

  span {
    position: absolute;
    width: clamp(200px, 8em, 350px);
    top: 0;
    text-align: center;
    font-size: clamp(0.3em, 1.1em, 2em);
    visibility: hidden;
    text-align: center;
    margin-top: 125px;
    font-family: 'Michroma', sans-serif;
  }

  svg:hover {
    transform: scale(1.05);
  }

  svg:hover ~ span {
    visibility: visible;
  }
}

.logoContainer {
  height: 80%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: absolute;
  opacity: 0.18;
  border-radius: 50%;
  animation: pulse 5s ease-in-out 2s infinite both alternate;
}

.logo {
  position: relative;
  width: 100%;
  height: 100%;
}

@keyframes pulse {
    from {opacity: 0.18;}
    to {opacity: 0.3;}
}