.applicationHeader {
  width: 100%;
  height: 75px;
  display: flex;
  padding-left: 2em;
}

.applicationHeaderNav {
  height: 100%;
  margin-left: auto;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 4em;
}

.applicationHeaderNavLink {
  margin: 0 1.3em;
  align-self: end;
  font-size: 1.15em;

  &.dropdown {
    position: relative;

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }

    &:hover .mediaLinks {
      height: auto;
    }
  }
}

.mediaLinks {
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 1px 3px white;
  z-index: 50;
  transition: 300ms ease all;
  height: 0;
  overflow: hidden;

  .mediaLink {
    height: 40px;
    padding: 0.7em 0.7em;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.applicationHeaderNavLink:last-child {
  margin-right: 0;
}

.applicationHeaderNavLink:hover {
  text-decoration: underline;
}
