.logoFigureMini {
  height: 100px;
  width: 100px;
  box-shadow: 0 0 5px 2px white;
  border-radius: 50%;
  transition: 150ms ease all;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  margin-top: 1.5em;
  z-index: 99;
}

.logoFigureMini:hover {
  box-shadow: 0 0 5px 5px white;
}

.logoFigure {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.logoCaption {
  width: 625px;
  font-size: 2.05em;
  line-height: 48px;
  bottom: 100px;
  z-index: 100;
  position: absolute;
  font-family: "Rajdhani";
  color: white;
  align-self: flex-end;
  text-align: center;
  text-transform: uppercase;
}
