:root {
  --theme_primary: rgb(182, 143, 191)
}

html,
body {
  padding: 0;
  margin: 0 auto;
  font-family: Rajdhani, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background: black;
  display: flex;
  justify-content:  center;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}